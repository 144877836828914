@font-face {
font-family: '__centra_d3920c';
src: url(/_nca/_next/static/media/f0b1e3f007006b62-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__centra_d3920c';
src: url(/_nca/_next/static/media/4c5a69d047c2b2dc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__centra_d3920c';
src: url(/_nca/_next/static/media/f35b5e6220b536eb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__centra_Fallback_d3920c';src: local("Arial");ascent-override: 95.65%;descent-override: 28.69%;line-gap-override: 0.00%;size-adjust: 104.55%
}.__className_d3920c {font-family: '__centra_d3920c', '__centra_Fallback_d3920c'
}.__variable_d3920c {--font-centra: '__centra_d3920c', '__centra_Fallback_d3920c'
}

